body {
  background-color: #0B3146;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #DCAE81;
  text-transform: uppercase;
  font-weight: 700;
}

h2 {
  font-size: 38px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px;
}

p {
  line-height: 1.5em;
}

a {
  color: inherit;
  transition: 0.3s;
  text-decoration: none;
}

a:hover {
  opacity: 0.8;
}

.btn,
a.btn {
  border: solid white 1px;
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
}

header,
section,
footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 900px;
  max-width: 100%;
  padding: 0 30px;
  gap: 50px;
}

.container>* {
  min-width: 0;
  max-width: 100%;
}

@media (max-width: 768px) {

  header,
  section,
  footer {
    align-items: center;
  }

  .container {
    flex-direction: column;
    justify-content: center;
  }
}