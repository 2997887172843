.servicos {
  padding-bottom: 4rem;
}

.servicos__imagem {
  width: 370px;
  max-width: 100%;
  border-radius: 50px;
}

.servicos__subtitulo span {
  display: block;
  font-size: 16px;
}

.servicos__lista {
  padding-inline-start: 20px;
}

.servicos__lista li {
  line-height: 1.5em;
}