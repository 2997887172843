header {
  padding-top: 4rem;
  padding-bottom: 2rem;
}

@media (max-width: 768px) {
  header .container {
    gap: 20px;
  }
}

ul.navbar {
  list-style: none;
  padding: 0;
  flex-grow: 1;
  text-align: right;
  margin: 0;
}