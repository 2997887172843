footer {
  background-color: #092A3C;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

footer .container {
  justify-content: space-between;
}

.footer__list {
  list-style: none;
  padding: 0;
  font-size: 14px;
  line-height: 2em;
}

.footer__list li,
.footer__list li a {
  display: flex;
  align-items: center;
  gap: .5em;
}

li.footer__address {
  align-items: flex-start;
  line-height: 1em;
  margin-bottom: 1em;
}

.footer__address p {
  margin: 0;
}