.quemsomos .container {
  padding-bottom: 4rem;
}

.quemsomos .container > * {
  flex-basis: 50%;
}

@media (max-width: 600px){
  .quemsomos .container {
    flex-direction: column-reverse;
  }
}